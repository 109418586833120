:global(.cui ul).root {
  cursor: default;
  list-style-type: none;
  display: flex;
  padding: 0 var(--cui-spacing) 0;
  align-items: center;
}

:global(.cui ul).root .action {
  margin-right: var(--cui-spacing);
  width: 20px;
  padding: 0;
}

:global(.cui ul) .action button {
  padding: 0;
}

:global(.cui[data-theme='dark'] a:not(.btn)).linkAction {
  color: var(--cui-color-white);
}

:global(.cui a:not(.btn)).linkAction {
  color: var(--tb-btn-link-colour);
}

.linkAction span {
  position: relative;
  top: 2px;
}
