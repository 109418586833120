:global(.cui) .root :global(.modal__dialog) {
  width: 1300px;
}

:global(.cui) .root :global(.modal__content) {
  height: auto;
  width: 100%;
}

:global(.cui) .root :global(.modal__title h2) {
  --cui-modal-title-font-size: 30px;
}

.container {
  width: 90%;
  margin: 0 auto;
}
