.theme--light .notify__container {
  fill: #fafafa;
  stroke: #adb5bd;
}

.theme--light .notify__line {
  stroke: #bac7d2;
}

.theme--light .notify__node--blue {
  fill: #c2dce8;
}

.theme--light .notify__node--grey {
  fill: #dee7ee;
}
