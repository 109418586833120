.root {
  --table-row-hover: var(--cui-background-active) !important;
  overflow-wrap: break-word;
}

:global(.cui[data-theme='dark']) .root {
  --cui-table-striping-odd: var(--tb-blue-active);
  --table-row-hover: var(--tb-light-blue) !important;
  --table-resize-handle-color: var(--tb-light-blue);
}
