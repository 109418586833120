:global(.cui .panel a),
:global(.cui .panel a:focus),
:global(.cui .panel a:hover) {
  color: var(--cui-font-color);
}

:global(.cui .panel .truncate) {
  display: block;
}

.root:hover .actions {
  opacity: 1;
}

.actions {
  opacity: 0;
}

.labels {
  cursor: default;
}

.subTitle {
  color: var(--tb-faded-font-colour);
}

.truncateOneLineCard {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
