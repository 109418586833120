.root {
  --btn-unselected-background: var(--tb-blue-active);
  --btn-selected-background: var(--cui-color-sky);

  display: flex;
}

:global(.cui) .root :global(.form-group).memoryInput {
  flex-grow: 4;
  display: none;
  margin-bottom: var(--cui-spacing);
}

.unitSwitchContainer {
  flex-grow: 1;
  margin-left: var(--cui-spacing);
}

.unitSwitchContainer .unitSwitch {
  position: relative;
  top: 30px;
}

.unitSwitchContainer .unitSwitch :global(.btn) {
  height: 40px;
}

:global(.cui) .root :global(.btn-group .btn) {
  background-color: var(--btn-unselected-background);
  color: white;
}

:global(.cui) .root :global(.btn-group .btn.selected),
:global(.cui) .root :global(.btn-group .btn.selected:hover) {
  background-color: var(--btn-selected-background);
}

:global(.cui) .root :global(.btn-group .btn) {
  border: none;
}

:global(.cui) .root :global(.form-group) {
  margin: unset;
}
