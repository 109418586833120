.root {
  display: flex;
}

.formSection {
  margin-right: var(--cui-spacing);
}

:global(.cui .form-group).checkbox {
  margin-top: var(--cui-spacing);
}
