:global(.cui .panel.panel--loose).card {
  --tile-svg-color: #1e4471;
  --tile-card-light: #e6f5fb;
  --tile-card-light-hover: #d0eefa;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

:global(.cui .panel.panel--loose).card:hover {
  transition: background-color 0.3s;
}

:global(.cui:not([data-theme='dark']) .panel.panel--loose).card {
  background-color: var(--tile-card-light);
}

:global(.cui:not([data-theme='dark']) .panel.panel--loose).card:hover {
  background-color: var(--tile-card-light-hover);
}

:global(.cui a:not(.btn)).cardWrap {
  display: block;
}

:global(.cui[data-theme='dark']) .titleLink {
  color: white;
}

.card svg {
  transform: scale(1.2);
}

.card:nth-child(1) svg {
  width: 45px;
}

.card svg path {
  fill: var(--tile-svg-color);
}

.card:nth-of-type(1) svg path:nth-child(4),
.card:nth-of-type(1) svg path:nth-child(5) {
  fill: none;
  stroke: var(--tile-svg-color);
}

:global(.cui[data-theme='dark']) .card svg path {
  --tile-svg-color: var(--cui-color-white);
}

:global(.cui[data-theme='dark']) .card svg path {
  --tile-svg-color: var(--cui-color-white);
}
