.root {
  --search-icon: #333;
  --search-input-background: #e0e0e0;

  min-width: 400px;
}

:global(.cui[data-theme='dark']) .root {
  --search-icon: #d8d8d8;
  --search-input-background: var(--cui-color-gray-700);
}

:global(.cui) .root :global(.form-group) {
  --cui-form-size: 40px;
}

:global(.cui) .root :global(.form-group .form-group__text input) {
  background-color: var(--search-input-background);
  border: none;
  padding: 0 0 0 45px;
}

:global(.cui) .root :global(.form-group .form-group__text button) {
  color: var(--search-icon);
}
