.root,
.heading {
  display: flex;
}

.heading {
  align-items: center;
}

.heading h1 {
  margin-bottom: 0;
}

.heading {
  align-items: center;
}

.heading h1 {
  margin-bottom: 0;
}

.button a,
.button button {
  height: 40px;
  margin: auto 0;
  width: 200px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--cui-color-white) !important;
  font-size: 16px !important;
}
