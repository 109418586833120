:global(.cui .btn).saveBtn {
  white-space: nowrap;
  cursor: pointer;
}

:global(.cui .btn).saveBtn {
  border-width: var(--cui-button-border-thickness);
  border-color: var(--cui-color-warning);
  background-color: var(--cui-color-warning);
}

:global(.cui .btn).saveBtn:hover {
  border-width: var(--cui-button-border-thickness);
  border-color: var(--cui-color-warning);
  background-color: var(--cui-color-warning);
}
