.handles {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.handle {
  position: absolute;
  top: 50%;
  left: -9px;
  transform: translateY(-50%);
}

.handle:nth-child(2) {
  right: -9px;
  left: auto;
}

.selected .handles {
  display: block;
}
