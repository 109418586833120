.root {
  width: 15px;
  height: 15px;
  background-color: var(--cui-color-white);
  border: 2px solid var(--cui-theme-primary);
  cursor: pointer;
}

.root:active {
  cursor: grabbing;
}
