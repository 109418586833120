:global(.cui button).root {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s;
}

:global(.cui button).active {
  color: var(--tb-darkest-blue);
}

:global(.cui[data-theme='dark'] button).active {
  color: var(--cui-color-sky);
}
