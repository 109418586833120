.root {
  --table-row-hover: var(--cui-background-active) !important;
}

:global(.cui[data-theme='dark']) .root {
  --cui-table-striping-odd: var(--tb-blue-active);
  --table-row-hover: var(--tb-light-blue) !important;
  --table-resize-handle-color: var(--tb-light-blue);
}

.root :global([role='cell']) {
  padding-top: 0;
  padding-bottom: 0;
  align-self: center;
}
