:global(.cui) .root label {
  --cui-form-label-spacing: 0;
}

.unConfigured {
  border: 1px solid var(--cui-color-warning);
}

.configured {
  border: 1px solid var(--cui-color-white);
}

:global(.cui) .root :global(.form-group .form-group__text.select) select {
  padding: 5px;
}

.root :global(.form-group .form-group__text.select:after) {
  top: unset;
}
