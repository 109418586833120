.root {
  position: relative;
  width: 100%;
  padding-top: var(--cui-header-height);
  height: 100vh;
}

.svg {
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.board {
  width: 100%;
  height: 100%;
}
