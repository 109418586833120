.root {
  --network-colour: var(--cui-color-midnight);
  --network-name-colour: var(--cui-color-midnight);
  --network-name-background-colour: #f2f2f2;
  text-align: center;
  color: var(--network-colour);
  min-width: 200px;
}

:global(.cui[data-theme='dark']) .root {
  --network-colour: var(--cui-color-white);
  --network-name-background-colour: #333;
  --network-name-colour: var(--cui-color-white);
}

.line {
  display: block;
  width: 100%;
  height: 30px;
  border-bottom: 2px solid var(--network-colour);
  position: relative;
}

.line:before,
.line:after {
  content: '';
  display: block;
  width: 2px;
  height: 15px;
  background-color: var(--network-colour);
  position: absolute;
  bottom: -2px;
}

.line:after {
  right: 0;
}

.name {
  margin: 0 !important;
  background-color: var(--network-name-background-colour);
  color: var(--network-name-colour);
  display: inline-block;
  padding: 3px 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
