.root {
  display: block;
}

.root svg path {
  fill: var(--cui-color-white);
}

.root:nth-of-type(1) svg path:nth-child(4),
.root:nth-of-type(1) svg path:nth-child(5) {
  fill: none;
  stroke: var(--cui-color-white);
}

:global(.cui[data-theme='dark']) .card svg path {
  --tile-svg-color: var(--cui-color-white);
}
