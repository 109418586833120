.contentWrapper {
  padding: 25px;
}

.closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

:global(.cui .modal) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:global(.cui .modal.modal--small .modal__dialog) {
  width: var(--tb-modal-width-small);
}

:global(.cui .modal .modal__content) {
  border-radius: 25px;
}

:global(.cui[data-theme='dark'] .modal) {
  --cui-modal-background: var(--tb-blue);
}
