.root {
  --btn-unselected-background: #1f2d3f;
  --btn-selected-background: var(--cui-color-sky);
}

.icon {
  display: inline-block;
}

.root svg {
  width: 15px;
  height: 14px;
  margin-top: 8px;
}

/* Light Theme */
:global(.cui .btn-group) .root {
  color: var(--cui-color-gray-200);
  display: flex;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid #07101d;
  background-color: var(--btn-unselected-background);
  padding: 0 30px;
  height: var(--tb-header-btn-height);
}

:global(.cui .btn-group) .selected,
:global(.cui .btn-group) .selected:hover {
  background-color: var(--btn-selected-background);
}
