.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.root :global(.btn) {
  min-width: 140px;
}
