.root {
  width: 120px;
  height: 120px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

:global(.cui) .title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
  margin-bottom: var(--cui-spacing-half);
}

.root .osIcon {
  position: absolute;
  width: 20%;
  left: 20px;
  top: 80px;
}
