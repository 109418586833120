.root {
  --primary-btn-background: var(--cui-color-ocean);
}

.root:active {
  transform: scale(0.95);
}

:global(.cui[data-theme='dark']) .root {
  --primary-btn-background: var(--cui-color-sky);
}

:global(.cui .btn.btn--success).root {
  --primary-btn-background: var(--cui-color-success);
}

.loading :global(.loader) {
  transform: scale(0.8);
  padding: 3px;
}

.loading :global(.loader p) {
  display: none;
}

:global(.cui .btn.btn--primary).root,
:global(.cui .btn.btn--success).root {
  background-color: var(--primary-btn-background);
  border: var(--add-btn-background);
  color: var(--cui-color-white);
}

:global(.cui .btn.btn--primary).root:disabled,
:global(.cui .btn.btn--success).root:disabled {
  opacity: 0.4;
}
