:global(.cui) .image {
  max-width: 70px;
}

:global(.cui .panel) .link {
  display: block;
}

:global(.cui .panel) .link:hover,
:global(.cui .panel) .link:active,
:global(.cui .panel) .link:focus {
  text-decoration: none;
}
