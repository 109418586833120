.root {
  cursor: default;
  padding: 0 var(--cui-spacing) 0;
}

:global(.cui) .root button,
:global(.cui) .root a {
  margin-right: var(--cui-spacing);
  width: 20px;
  padding: 0;
  color: var(--tb-btn-link-colour);
}

:global(.cui[data-theme='dark']) .root button,
:global(.cui[data-theme='dark']) .root a {
  color: var(--cui-color-white);
}
