.root {
  display: flex;
  flex-direction: column;
  gap: var(--cui-spacing);
}

:global(.cui) .root label {
  --cui-form-label-spacing: var(--cui-spacing-half);
  padding: 0;
  width: 75px;
}

:global(.cui) .root :global(.form-group) .readOnly {
  border: none;
  opacity: 1;
}

.quantity {
  width: 100px;
}

:global(.cui) .quantity :global(.form-group .form-group__text.select):after {
  top: 50px;
}

:global(.cui .btn.btn--success).submitBtn,
:global(.cui .btn.btn--success).submitBtn:hover {
  color: var(--cui-font-color);
  padding: 0 calc(var(--cui-spacing-dbl) * 2);
}

:global(.cui .btn.btn--success).submitBtn:disabled {
  background-color: var(--cui-theme-success);
  border-color: var(--cui-theme-success);
  opacity: 0.4;
}
