.button {
  transition: all var(--cui-animate-speed) var(--cui-animate-timing-function);
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0;
  color: var(--cui-inactive-color);
  text-align: center;
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  box-shadow: 0 none;
  --cui-tab-border-active: var(--cui-accent-color);
  border: none;
  outline: none;
}

.button:hover {
  transition: all var(--cui-animate-speed) var(--cui-animate-timing-function);
  box-shadow: 0 calc(var(--cui-accent-thickness) * -1) 0
    var(--cui-tab-border-active) inset;
  color: var(--cui-active-color);
  cursor: pointer;
}

.button:focus {
  box-shadow: 0 calc(var(--cui-accent-thickness) * -1) 0
    var(--cui-tab-border-active) inset;
}

.active {
  box-shadow: 0 calc(var(--cui-accent-thickness) * -1) 0
    var(--cui-tab-border-active) inset;
}

.inactive button {
  color: var(--cui-inactive-color);
}

.bordered {
  background-color: var(--cui-color-gray-300);
  border: var(--cui-border);
  --cui-tab-background: var(--cui-background-color);
  padding: 8px 16px;
  line-height: 21px;
  margin-right: 5px;
}

:global(.cui[data-theme='dark']) .bordered {
  background-color: var(--cui-tab-background-active);
}

.borderedActive {
  box-shadow: none;
  background-color: transparent;
  border-bottom-color: #ffffff;
}

:global(.cui[data-theme='dark']) .bordered {
  background-color: transparent;
}

:global(.cui[data-theme='dark']) .borderedActive {
  border-bottom-color: var(--cui-color-gray-800);
  background-color: var(--cui-color-gray-800);
}

.bordered:hover,
.bordered:focus {
  box-shadow: none;
}
