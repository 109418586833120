:root {
  --tb-light-blue: #2f4a70;
  --tb-light-blue-grey: #adb5bd;
  --tb-blue: #0f2031;
  --tb-blue-active: #162f47;
  --tb-darkest-blue: #07101d;
  --tb-btn-link-colour: var(--cui-font-color);
  --tb-card-background: var(--cui-color-gray-200);
  --tb-card-hover-background: #e0f7fe;
  --tb-dark-background: #0f2031;
  --tb-faded-font-colour: var(--cui-color-gray-600);
  --tb-dcloud-logo-colour: #017cad;
  --tb-hero-background: #e6f5fb;
  --tb-content-width: 1200px;
  --tb-configuration-form-width: 700px;
  --tb-header-btn-height: 35px;
  --cui-header-background: var(--cui-color-midnight);
  --tb-modal-width-small: 650px;
}

:root .cui[data-theme='dark'] {
  --tb-btn-link-colour: var(--cui-color-white);
  --tb-card-background: var(--tb-dark-background);
  --tb-card-hover-background: #162e47;
  --tb-faded-font-colour: var(--cui-color-gray-500);
  --tb-dcloud-logo-colour: var(--cui-color-white);
  --tb-hero-background: #0e284d;
  --tb-table-striping-odd: #1d2b38;
}
