.root {
  width: 100px;
  height: 100px;
  text-align: center;
}

.root svg {
  width: 80%;
  margin: 0 auto;
}

:global(.cui) .title {
  color: var(--cui-color-sky);
  margin: 0;
}
