.root {
    margin-bottom: var(--cui-spacing-dbl);
  }
  
  :global(.cui) .natTarget {
    width: calc(var(--cui-spacing-dbl) * 10);
  }
  
  :global(.cui) .asset {
    width: calc(var(--cui-spacing-dbl) * 6);
  }
  
  :global(.cui) .hostname {
    width: calc(var(--cui-spacing-dbl) * 8);
  }