.root {
  --side-menu-background: var(--cui-color-gray-500);
  position: absolute;
  top: var(--cui-spacing-dbl);
  left: var(--cui-spacing-dbl);
  background-color: var(--side-menu-background);
  padding: var(--cui-spacing) var(--cui-spacing-half);
  border-radius: 30px;
}

:global(.cui[data-theme='dark']) .root {
  --side-menu-background: var(--tb-darkest-blue);
}
