:root {
  --tb-toast-border-colour: #f2f2f2;
  --tb-toast-width: 320px;
}

:root :global(.cui[data-theme='dark']) {
  --tb-toast-border-colour: #343a40;
}

:global(.cui .toast).root {
  width: var(--tb-toast-width);
  margin: var(--cui-spacing);
  border: 1px solid var(--tb-toast-border-colour);
}
