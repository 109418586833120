.root {
  display: flex;
}

.verticalAlign {
  align-self: center;
}

.targetField {
  width: calc(var(--cui-spacing) * 15);
}
