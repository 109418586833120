.root {
  display: flex;
  margin-bottom: 50px;
}

.formSection {
  margin-right: var(--cui-spacing);
}

:global(.cui .form-group).checkbox {
  margin-top: var(--cui-spacing);
}

.button {
  margin-top: 30px;
}
