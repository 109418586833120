.root :global(.container) {
  max-width: 1240px;
}

.content {
  --offset-to-stop-scroll: 20px;

  padding-top: var(--cui-header-height);
  min-height: calc(
    100vh - var(--cui-header-height) - var(--offset-to-stop-scroll)
  );
}

:global(.cui .footer).footer {
  margin-top: 0;
}
