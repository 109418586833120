.root {
  --btn-selected-background: var(--cui-color-sky);
  --btn-unselected-background: #162e47;
  --button-background-color-light: #dee2e6;
  width: 280px;
}

:global(.cui .btn-group) .startStopButton {
  border: none;
  background-color: var(--btn-unselected-background);
  height: 35px;
  flex: 1;
}

:global(.cui[data-theme='light']) .inactive {
  background-color: var(--button-background-color-light);
  color: var(--cui-color-gray-800);
}

:global(.cui .btn-group) .active,
:global(.cui .btn-group) .active:hover {
  background-color: var(--btn-selected-background);
  color: var(--cui-color-white);
}
