:global(.cui .form-group).select :global(.form-group__text.select) select {
  border: none;
  padding: 15px;
  max-height: none;
  border-radius: 3px;
}

:global(.cui .form-group .form-group__text.select).formGroupText label {
  margin-bottom: 0;
}

:global(.cui .form-group .form-group__text.select:after) {
  top: 50px;
  right: 15px;
}
