.root {
  display: inline-block;
  position: absolute;
  border: 2px solid transparent;
  padding: 5px;
  transform-origin: 0 0;
}

.root:hover,
.selected {
  border: 2px solid var(--cui-theme-primary);
}

.handle {
  display: none;
  width: 15px;
  height: 15px;
  background-color: var(--cui-color-white);
  position: absolute;
  top: 50%;
  left: -9px;
  transform: translateY(-50%);
}

.handle:nth-child(2) {
  right: -9px;
  left: auto;
}

.selected .handle {
  display: block;
}

.editable {
  cursor: grab;
}

.editable:active {
  cursor: grabbing;
}
