.root {
  padding-top: var(--cui-spacing);
}

.root [role='cell'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 38px;
  padding: 0 var(--cui-table-padding);
}

.root [role='cell'] select {
  margin: auto 0;
}
