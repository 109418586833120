.root {
  width: 120px;
  height: 150px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

.title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}
