:global(.cui ul).root {
  cursor: default;
  list-style-type: none;
  display: flex;
  padding: 0;
}

:global(.cui ul).root .action {
  margin-right: var(--cui-spacing);
  width: 20px;
  padding: 0;
}
