.root {
  --accordion-button-bg-colour: var(--cui-color-gray-300);
  --accordion-button-bg-active: var(--cui-color-gray-400);
}

:global(.cui[data-theme='dark']) .root {
  --accordion-button-bg-colour: var(--tb-blue);
  --accordion-button-bg-active: var(--tb-blue-active);
}

.root,
.button {
  width: 100%;
}

.button {
  text-align: left;
  padding: var(--cui-spacing) var(--cui-spacing-dbl);
  border: none;
  background-color: var(--accordion-button-bg-colour);
  cursor: pointer;
  transition: background-color 0.15s;
}

.button:hover,
.active .button {
  background-color: var(--accordion-button-bg-active);
}

.content {
  overflow: hidden;
}

.childrenWrap {
  padding: var(--cui-spacing-dbl);
}
