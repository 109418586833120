.root {
  --view-toggle-unselected-background-color: var(--cui-color-white);
  --view-toggle-unselected-color: var(--tb-darkest-blue);
  --view-toggle-border-color: var(--tb-darkest-blue);
  --view-toggle-selected-background-color: var(--tb-darkest-blue);
  --view-toggle-selected-color: var(--cui-color-white);
}

:global(.cui[data-theme='dark']) .root {
  --view-toggle-unselected-background-color: #1f2d3f;
  --view-toggle-selected-background-color: var(--cui-color-sky);
  --view-toggle-selected-color: var(--tb-darkest-blue);
  --view-toggle-unselected-color: var(--cui-color-white);
}

:global(.cui .btn-group).root > :global(.btn) {
  height: 40px;
  border: none;
  background-color: var(--view-toggle-selected-background-color);
  color: var(--view-toggle-selected-color);
  border: 1px solid var(--view-toggle-border-color);
}

:global(.cui .btn-group).root > :global(.btn--ghost) {
  height: 40px;
  background-color: var(--view-toggle-unselected-background-color);
  color: var(--view-toggle-unselected-color);
}
