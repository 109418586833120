.root {
  margin-bottom: var(--cui-spacing-dbl);
}

:global(.cui) .targetInput {
  width: calc(var(--cui-spacing-dbl) * 6);
}

:global(.cui) .ipAddressInput {
  width: calc(var(--cui-spacing-dbl) * 5);
}

:global(.cui) .tcpPortInput {
  width: calc(var(--cui-spacing-dbl) * 3);
}

.verticalAlign {
  align-self: center;
}
